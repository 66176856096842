/*
 * 업무구분: 연간소득내역
 * 화 면 명: MSPAP141M
 * 화면설명: 연간소득내역
 * 접근권한: 
 * 작 성 일: 최준석
 * 작 성 자: 2022.12.22
 */
<template>
  <ur-page-container class="msp" :show-title="true" title="연간소득내역" type="subpage" :topButton="true" :action-type="isManager ? 'search' : 'subpage'" @action-search-click="fn_OpenMSPAP106P" @on-header-left-click="fn_goMain">

    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <!-- 퍼블 : 230508 추가 내역 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
        <mo-button class="btn_popup_back" @click="fn_goMain" />
        <ur-box-container alignV="start" componentid="" direction="row" class="gray-box bd-T-Ty1 mb0" v-if="isManager">
          <div class="fexTy5">
            <b class="fs18rem crTy-bk1" @click="fn_OpenCnstCard">{{cnsltNm}}</b>
            <span class="ml4 fs16rem crTy-bk1">({{cnsltNo}})</span>
          </div>
        </ur-box-container>
      </ur-box-container>
      <!-- 퍼블 : 230508 추가 내역 //-->

      <ur-box-container alignV="start" componentid="" direction="row" class="con-area row-box mt30 " >
        <span class="label-title w100px">조회년도</span>
        <mo-year-picker v-model="targetYear" class="full" @confirmed="fn_GetAnulActualAmt" />
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="row" class="con-area mt20 msp-ap-wrap" >
        <ur-box-container alignV="start" componentid="" direction="column" class="income-year-total mb15" >
          <div class="conut"><strong>{{anulTotIncmAmt}}</strong>원</div>
          <div class="text mt5">누적소득 <span>{{stdMonth}}</span></div>
          <!-- <div class="bg"></div> -->
        </ur-box-container>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="income-year-chart">
          <mo-list :list-data="aPActualAmtSVOList">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <div class="info_box">
                      <span class="text">{{item.stndYm}}</span>
                      <span class="count"><strong>{{$bizUtil.numberWithCommas(Math.trunc(item.incmAmt))}}</strong>원</span>
                      <div class="bar-chart">
                      <!-- <div class="bar-chart" v-if="item.incmAmt > 0 && !$bizUtil.isEmpty(item.incmAmt)"> -->
                        <div class="fill-bar" :style="{width:'calc('+item.bar+')'}"></div> <!--:style="width:"-->
                        {{item.bar}}
                      </div>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
          <div class="info con-area mt30">
            <mo-icon icon="msp-info" /><span class="ml5 crTy-bk7">누적소득은 지급조서기준입니다.</span>
            <br/>
            <mo-icon icon="msp-info" /><span class="ml5 crTy-bk7">전월소득은 당월 4영업일부터 조회됩니다.</span>
          </div>
        </ur-box-container>
      </ur-box-container>

      <!-- no-data --> 
      <!-- <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
        <mo-list-item ref="expItem">
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span class="ns-ftcr-gray"><p class="mt20">등록된 게시물이 없어요.</p></span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container> -->

    </ur-box-container>
    <!-- 스크롤 List -->
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPAP106P from '@/ui/ap/MSPAP106P'
  import MSPAP511P from '@/ui/ap/MSPAP511P'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP141M",
    screenId: "MSPAP141M",
    components: {},
    data() {
      return {
        targetYear: '', // 년 조회
        aPActualAmtSVOList: [],
        anulTotIncmAmt: '', // 누적소득
        stdMonth: '',  // 기준일자
        year: '',
        isManager: false,
        cnsltNo: '',
        cnsltNm: ''
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)

      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        this.isManager = true
      } else {
        this.isManager = false
      }

      this.targetYear = String(moment(new Date()).format('YYYY'))
    },
    mounted() {

      if ( !this.isManager ) {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
        this.fn_GetAnulActualAmt()
      } else {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "",
            content: '컨설턴트를 먼저 선택해 주세요.',
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_OpenMSPAP106P()
            },
            onPopupCancel: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_OpenMSPAP106P()
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_OpenMSPAP106P()
            }
          }
        })
      }
    },
    activated() {},
    beforeDestroy () {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_OpenMSPAP106P(){
        const lp_Vm = this  
        this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
          properties: {
            pPage: 'MSPAP141M',
          },
          listeners: {
            onPopupSearch: (pData) => {
              console.log('onPopupSearch >> ' , pData)
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)

              if(pData != null && pData != undefined){
                lp_Vm.cnsltNo = pData.key
                lp_Vm.cnsltNm = pData.label
              }

              lp_Vm.fn_GetAnulActualAmt()
            },
            onPopupClose: () => {
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)
            }
          }
        })
      },
      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
        }else{
          this.$router.go(-1)
        }
      },
      /******************************************************************************
       * Function명 : fn_GetAnulActualAmt
       * 설명       : 연간소득내역을 조회한다.
      ******************************************************************************/
      fn_GetAnulActualAmt () {
        var lv_Vm = this
        var trnstId = 'txTSSAP00S5'

        var pParam = {
          cnsltNo: this.cnsltNo,
          strYm: this.targetYear + '01'
        }

        this.aPActualAmtSVOList = []
        this.anulTotIncmAmt = 0

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {

          if ( response.body != null ) {
            if ( response.body.aPActualAmtSVOList != null && response.body.aPActualAmtSVOList.length > 0 ) {
              lv_Vm.stdMonth = response.body.stndYm
              lv_Vm.anulTotIncmAmt =  lv_Vm.$bizUtil.numberWithCommas(Math.trunc(response.body.anulTotIncmAmt))
              lv_Vm.aPActualAmtSVOList = response.body.aPActualAmtSVOList

              if ( Number(lv_Vm.targetYear) < new Date().getFullYear() ) {
                lv_Vm.stdMonth = lv_Vm.targetYear + '-12 기준'
              } else {
                lv_Vm.stdMonth = response.body.stndYm + ' 기준'
              }

              let maxIncmAmt = 0
              lv_Vm.aPActualAmtSVOList.forEach(item => {
                if(!lv_Vm.$bizUtil.isEmpty(item.incmAmt) && item.incmAmt > 0){
                  if(Number(maxIncmAmt) < Number(item.incmAmt)) maxIncmAmt = item.incmAmt
                }
              })

              lv_Vm.aPActualAmtSVOList.forEach(item => {
                if(maxIncmAmt > 0){
                  if(!lv_Vm.$bizUtil.isEmpty(item.incmAmt) && item.incmAmt > 0){
                    item.bar = (item.incmAmt / maxIncmAmt * 100) + '%'
                  }else{
                    item.bar = '0%'
                  }
                }
              })

            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
      },
      fn_OpenCnstCard() {
        this.popupAP511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: this.cnsltNm,
            pCnsltNo: this.cnsltNo,
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.popupAP511)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.popupAP511)
            }
          }
        })
      }
    }
  };
</script>
<style scoped>
</style>